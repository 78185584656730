<template>
  <v-navigation-drawer
    :value="isAddPaymentSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="(val) => $emit('update:is-add-payment-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <h4 class="font-weight-semibold text-base">
          {{ t('transactions.pay_now') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-payment-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="form">
          <!-- amount -->
          <v-text-field
            v-model.number="computedAmountTransactionSelected"
            outlined
            :label="t('transactions.payment_amount')"
            dense
            hide-details="auto"
            class="mb-6"
            readonly
            :rules="[required]"
          ></v-text-field>

          <!-- date -->
          <v-text-field
            v-model="paymentDate"
            outlined
            dense
            :label="t('transactions.date')"
            :prepend-icon="icons.mdiCalendar"
            readonly
            :rules="[required]"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <!-- <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            readonly
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="paymentDate"
                outlined
                dense
                label="Date"
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                hide-details="auto"
                class="mb-6"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="paymentDate"
              color="primary"
              readonly
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu> -->

          <!-- payment method -->
          <v-select
            v-model="addPaymentData.payment_method_id"
            :items="computedpaymentOptions"
            item-text="number_owner"
            item-value="id"
            :label="t('transactions.payment_method')"
            :placeholder="t('transactions.payment_method')"
            outlined
            class="mt-2"
            dense
            :rules="[required]"
          ></v-select>

          <!-- note -->
          <v-textarea
            v-model="addPaymentData.note"
            :label="t('transactions.note')"
            :placeholder="t('transactions.note')"
            outlined
            class="mt-2"
            :rules="[required]"
          >
          </v-textarea>

          <div class="d-flex flex-wrap mt-3 justify-center">
            <v-btn
              color="secondary"
              class="me-3"
              @click="onCreate"
            >
              {{ t('transactions.pay') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="onCancel"
            >
              {{ t('tooltip.cancel') }}
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mdiClose } from '@mdi/js'
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { createPayment, getPaymentMethodsMin, getPaymentMethodsByGroupMin } from '@api'
import { error, success } from '@core/utils/toasted'
import { formatCurrency, unformatCurrency } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import { required } from '@core/utils/validation'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@/@core/utils/useSelectOptions'

export default {
  model: {
    prop: 'isAddPaymentSidebarActive',
    event: 'update:is-add-payment-sidebar-active',
  },
  props: {
    isAddPaymentSidebarActive: {
      type: Boolean,
      required: true,
    },
    transactionSelected: {
      type: Array,
      default: () => [],
    },
    amountTransactionSelected: {
      type: Number,
      default: 0,
    },
    groupId: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const { userData } = useCryptoJs()
    const {
      configOrganization,
    } = useSelectOptions()

    const date = new Date().toISOString().substr(0, 10)
    const computedTransactionSelected = computed(() => props.transactionSelected)
    const computedAmountTransactionSelected = computed(() => formatCurrency(props.amountTransactionSelected))
    const computedGroupId = computed(() => props.groupId)
    const computedIsAdd = computed({
      set: val => emit('update:is-add-payment-sidebar-active', val),
      get: () => props.isAddPaymentSidebarActive,
    })
    const paymentDate = new Date().toISOString().substr(0, 10)
    const addPaymentData = ref({
      amount: computedAmountTransactionSelected.value,
      payment_method_id: null,
      note: null,
      transactions: computedTransactionSelected.value,
      username: userData.value.username,
      group_id: userData.value.role !== 'A' ? userData.value.group_id : configOrganization.value,
    })
    const paymentOptions = ref([])
    const computedpaymentOptions = computed(() => paymentOptions.value)
    const { t } = useUtils()

    const loadData = async id => {
      let response = null
      if (id) {
        response = await getPaymentMethodsByGroupMin(id)
      } else if (userData.value && userData.value.role !== 'A') {
        response = await getPaymentMethodsByGroupMin(userData.value.group_id)
      } else {
        response = await getPaymentMethodsMin()
      }

      paymentOptions.value = response.data
    }

    watch([computedIsAdd], async () => {
      if (computedIsAdd.value) {
        if (computedGroupId.value) {
          await loadData(computedGroupId.value)
        } else {
          await loadData()
        }
      } else {
        addPaymentData.value = {
          amount: computedAmountTransactionSelected.value,
          payment_method_id: null,
          note: null,
          transactions: computedTransactionSelected.value,
          username: userData.value.username,
          group_id: userData.value.role !== 'A' ? userData.value.group_id : configOrganization.value,
        }
      }
    })

    const onCreate = async () => {
      if (form.value.validate()) {
        addPaymentData.value.amount = unformatCurrency(computedAmountTransactionSelected.value)
        setTimeout(async () => {
          const resp = await createPayment(addPaymentData.value)
          let message = null
          if (resp.ok) {
            message = resp.message
            emit('update:is-add-payment-sidebar-active', false)
            addPaymentData.value = {
              amount: computedAmountTransactionSelected.value,
              payment_method_id: null,
              note: null,
              transactions: computedTransactionSelected.value,
              username: userData.value.username,
              group_id: userData.value.role !== 'A' ? userData.value.group_id : configOrganization.value,
            }
            emit('reload')
            success(message)
          } else {
            message = resp.message.text
            error(message)
          }
          emit('openCloseNew', false)
        }, 200)
      }
    }

    const onCancel = () => {
      emit('update:is-add-payment-sidebar-active', false)
      emit('clearTransaction')
    }

    onMounted(async () => {
      await loadData()
    })

    return {
      // data
      form,
      date,
      addPaymentData,
      paymentOptions,
      paymentDate,

      // computed
      computedTransactionSelected,
      computedAmountTransactionSelected,
      computedGroupId,
      computedIsAdd,
      computedpaymentOptions,

      // methods
      loadData,
      required,
      onCreate,
      onCancel,
      formatCurrency,

      // i18n
      t,

      // icons
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
